<template>
    <div v-if="showBanner" class="notification-banner">
      <div class="notification-content">
        <p>
          By using this website, you agree to our 
          <a href="/privacy-policy" target="_blank">Privacy Policy</a> and 
          <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>.
        </p>
        <button @click="dismissBanner">OK</button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  
  
      const showBanner = ref(true);
  
      const checkBannerDismissal = () => {
        const bannerDismissed = localStorage.getItem('bannerDismissed');
        if (bannerDismissed === 'true') {
          showBanner.value = false;
        }
      };
  
      const dismissBanner = () => {
        localStorage.setItem('bannerDismissed', 'true');
        showBanner.value = false;
      };
  
      onMounted(() => {
        checkBannerDismissal();
      });
  


  </script>
  
  <style scoped>
  .notification-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    z-index: 9999;
    opacity: 0.9;
  }
  
  .notification-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .notification-content a {
    color: #ffcc00;
    text-decoration: none;
  }
  
  button {
    background-color: #ffcc00;
    color: #333;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    margin-top: 10px;
  }
  </style>