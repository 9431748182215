import { createRouter, createWebHistory } from 'vue-router'
import { projectAuth } from '@/firebase/config'
import Payment from '../views/Payment.vue'
import Analyze from '../views/Analyze.vue'
import NotFound from '../views/NotFound.vue'
import { projectFirestore } from '../firebase/config'
import LandingPageTW from '../views/LandingPageTW.vue'
import LoginTW from '../views/auth/LoginTW.vue'
import SignupTW from '../views/auth/SignupTW.vue'
import MainTW from '../views/MainTW.vue'
import HomeTW from '../views/HomeTW.vue'
import GenericReport from '../views/report/GenericReport.vue'
import Subscribed from '../views/Subscribed.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
//route guard

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
    next({ name: 'Login' })
  } else {
    next()
  }
}


const requireAuthAndCredits = async (to, from, next) => {
  let user = projectAuth.currentUser
  if(!user){
    next({ name: 'Login' })
  }
  let reference = await projectFirestore.collection('users').doc(user.uid).get()
  let credits = reference.data().credits
  if (credits <= 0) {
    next({ name: 'Payment' })
  }
  else {
    next()
  }
}
const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPageTW,
  },
  {
    path: '/subscribed',
    name: 'Subscribed' ,
    component: Subscribed,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  { 
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,

  },

  {
    path: '/main',
    name: 'Main',
    component: MainTW,
    beforeEnter: requireAuth,
    children: [
      {
        path: '/payment',
        name: 'Payment',
        component: Payment,
        beforeEnter: requireAuth
      },
      {
        path: '/analyze',
        name: 'Analyze',
        component: Analyze,
        beforeEnter: requireAuth,
      },
      {
        path: '/analyze/:id',
        name: 'AnalysisDetails',
        component: GenericReport,
        beforeEnter: requireAuth,
        props: true
      },
      {
        path: '/home',
        name: 'Home',
        component: HomeTW,
        beforeEnter: requireAuth,
      }


    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginTW,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupTW,
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
