<template>
    <div class="bg-inherit py-6 sm:py-6">
      <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <!-- <h2 class="text-center text-base/7 font-semibold text-indigo-600">Deploy faster</h2> -->
        <!-- <p class="mx-auto mt-2 max-w-lg text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl">Everything you need to deploy your app</p> -->
        <div class="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
          <div class="relative lg:row-span-2">
             
              <div class="relative min-h-[28rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                <div class="absolute inset-x-10 bottom-0 top-10 overflow-hidden  bg-inherit ">
                  <div class="mx-auto  overflow-hidden">
                    <img class=" sm:size-full object-cover object-top" src="@/assets/cortaix-mobile.png" alt="" />
                </div>
                </div>
            </div>
          </div>
          
     

        </div>
      </div>
    </div>
  </template>
  <style scoped>
  .custom-text {
  font-weight: 600;
  color: #333333;
}
</style>
  