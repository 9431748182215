<template>
 <header class="fixed   transition-all duration-300 ease-in-out inset-x-0 top-0 z-50" :class="[isScrolled ? 'bg-white shadow-sm' : 'bg-transparent']">
      <nav class="flex items-center justify-between pt-6 pb-3 lg:px-8 mx-auto max-w-2xl px-6 lg:max-w-7xl" aria-label="Global">

        <div class="flex lg:flex-1">
            <span class="sr-only ">CortAIx</span>
            <p class="nav-home2">CortAIx</p>
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-center gap-8  ">
          <a  class="nav-link2" @click="sendProductButtonEvent">Product</a>
          <a  class="nav-link2" @click="sendCustomersButtonEvent">Customers</a>
          <a  class="nav-link2" @click="sendFeaturesButtonEvent">Features</a>
          <a  class="nav-link2" @click="sendSecurityButtonEvent">Security</a>
          <a  class="nav-link2" @click="sendDemoButtonEvent">Contact</a>
        </div>
        <!--request demo button -->
        <div class="lg:flex lg:flex-1 lg:justify-end">
          <div @click="sendDemoButtonEvent">
            <ButtonInverted text="Request a demo" :disabled="false"  />
          </div>
        </div>
        <!-- <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div> -->

        <!-- <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <router-link :to="{ name: 'Login' }" class="nav-link2">Log in <span aria-hidden="true">&rarr;</span></router-link>
        </div> -->
      </nav>
      <!-- <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-50" />
        <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">Your Company</span>
              <img class="h-8 w-auto" src="@/assets/artificial-intelligence.png" alt="" />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-400" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/25">
              <div class="space-y-2 py-6">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-200">{{ item.name }}</a>
              </div> 
               <div class="py-6">
                <router-link :to="{ name: 'Login' }" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</router-link>
              </div> 
            </div>
          </div>
        </DialogPanel>
      </Dialog> -->
    </header>
</template>

<script setup>
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { ref, onMounted, onUnmounted } from 'vue'
import ButtonInverted from '@/components/buttons/ButtonInverted.vue'
import { defineEmits } from 'vue';
const emit = defineEmits(['clickedOnDemoButton', 'clickedOnProductButton', 'clickedOnContactButton', 'clickedOnFeaturesButton', 'clickedOnSecurityButton', 'clickedOnCustomersButton']);
const sendDemoButtonEvent = () => {
  emit('clickedOnDemoButton');
};
const sendProductButtonEvent = () => {
  emit('clickedOnProductButton');
};
const sendContactButtonEvent = () => {
  emit('clickedOnContactButton');
};
const sendFeaturesButtonEvent = () => {
  emit('clickedOnFeaturesButton');
};
const sendSecurityButtonEvent = () => {
  emit('clickedOnSecurityButton');
};
const sendCustomersButtonEvent = () => {
  emit('clickedOnCustomersButton');
};
const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Customers', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Security', href: '#' },
  { name: 'Contact', href: '#' },
]
const mobileMenuOpen = ref(false)


const isScrolled = ref(false)

const handleScroll = () => {
  isScrolled.value = window.scrollY > 50
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

</script>

<style lang="scss" scoped>

</style>