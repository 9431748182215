<template>
  <div class="container">
    <header>
      <h1>Terms and Conditions</h1>
    </header>
    <main>
      <section id="terms-and-conditions">
        <h2>1. User Responsibilities</h2>
        <p>
          You agree to use this website only for lawful purposes. The following activities are strictly prohibited:
        </p>
        <ul>
          <li>Spamming or sending unsolicited messages.</li>
          <li>Misusing the website in any way that could harm its functionality or accessibility.</li>
          <li>Engaging in activities that violate applicable laws or infringe upon the rights of others.</li>
        </ul>

        <h2>2. No Refund Policy</h2>
        <p>We do not offer refunds for any interactions or services provided through this website.</p>

        <h2>3. Disclaimer of Liability</h2>
        <p>
          We are not responsible for any direct, indirect, or consequential damages arising from your use of this
          website. Use it at your own risk.
        </p>

        <h2>4. Governing Law</h2>
        <p>
          These terms and conditions are governed by the laws of Germany, specifically the jurisdiction of Berlin. Any
          disputes shall be resolved exclusively under German law.
        </p>

        <h2>5. Amendments</h2>
        <p>
          We reserve the right to amend these terms and conditions at any time. Continued use of the website indicates
          your acceptance of the updated terms.
        </p>

        <p>If you have any questions about these terms, please contact us at <a href="mailto:[anis.chihi@cortaix.com]">[anis.chihi@cortaix.com]</a>.</p>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped>
.container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 800px;
  padding: 2rem 1.5rem;
  background-color: #f9f9f9;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  background-color: #0077cc;
  color: white;
  padding: 1rem;
  border-radius: 8px 8px 0 0;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  color: #0056a3;
  margin-top: 2rem;
}

p {
  text-align: justify;
  margin-bottom: 1rem;
}

ul {
  margin-left: 1.5rem;
}

ul li {
  margin-bottom: 0.5rem;
  list-style: disc;
}

a {
  color: #0077cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>