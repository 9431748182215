<template>
  <div class="container">
    <header>
      <h1>Privacy Policy</h1>
    </header>
    <main>
      <section id="privacy-policy">
        <p>Your privacy is important to us. This policy outlines how we collect, use, and protect your personal data.</p>

        <h2>1. Data We Collect</h2>
        <p>We collect the following personal information when you use our website:</p>
        <ul>
          <li>Your first name and last name.</li>
          <li>Your email address.</li>
          <li>Your company name.</li>
          <li>Your inquiry message.</li>
        </ul>

        <h2>2. How We Use Your Data</h2>
        <p>We use the information you provide for the following purposes:</p>
        <ul>
          <li>To respond to your inquiries and connect with potential customers.</li>
          <li>To improve the functionality and experience of our website.</li>
          <li>For future analytics purposes (e.g., to better understand user behavior).</li>
        </ul>

        <h2>3. Cookies</h2>
        <p>Our website uses cookies that are essential for its proper functioning. These cookies do not collect personal data and are required to provide basic features.</p>

        <h2>4. Data Sharing</h2>
        <p>We do not share your personal data with any third parties.</p>

        <h2>5. Your Rights</h2>
        <p>You have the right to request the removal of your personal data at any time. To do so, please contact us at <a href="mailto:[anis.chiih@cortaix.com">[anis.chiih@cortaix.com]</a>.</p>

        <h2>6. Data Security</h2>
        <p>
          We take appropriate measures to secure your data and protect it from unauthorized access. However, no method
          of transmission over the Internet is completely secure.
        </p>

        <h2>7. Amendments</h2>
        <p>
          We reserve the right to update this privacy policy at any time. Please review this page periodically to stay
          informed of any changes.
        </p>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 800px;
  padding: 2rem 1.5rem;
  background-color: #f9f9f9;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  background-color: #0077cc;
  color: white;
  padding: 1rem;
  border-radius: 8px 8px 0 0;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  color: #0056a3;
  margin-top: 2rem;
}

p {
  text-align: justify;
  margin-bottom: 1rem;
}

ul {
  margin-left: 1.5rem;
}

ul li {
  margin-bottom: 0.5rem;
  list-style: disc;
}

a {
  color: #0077cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>