<template>
  <div class="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true">
      <div
        class="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
        style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="mx-auto max-w-2xl text-center">
      <h2 class="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Contact sales</h2>
      <p class="mt-2 text-lg/8 text-gray-600">Want to see our solution firsthand? Fill out the form, and we’ll arrange a live demo tailored to your needs.</p>
    </div>
    <form action="#" method="POST" class="mx-auto mt-16 max-w-xl sm:mt-20" @submit.prevent="submitForm">
      <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div>
          <label for="first-name" class="block text-sm/6 font-semibold text-gray-900">First name</label>
          <div class="mt-2.5">
            <input required v-model="formData.firstName" type="text" name="first-name" id="first-name"
              autocomplete="given-name"
              class="block w-full rounded-md border-none bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:border-none " />
          </div>
        </div>
        <div>
          <label for="last-name" class="block text-sm/6 font-semibold text-gray-900">Last name</label>
          <div class="mt-2.5">
            <input v-model="formData.lastName" type="text" name="last-name" id="last-name" autocomplete="family-name"
              class="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 border-none" />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="company" class="block text-sm/6 font-semibold text-gray-900">Company</label>
          <div class="mt-2.5">
            <input v-model="formData.company" type="text" name="company" id="company" autocomplete="organization"
              class="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline border-none" />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="email" class="block text-sm/6 font-semibold text-gray-900">Email</label>
          <div class="mt-2.5">
            <input v-model="formData.email" type="email" name="email" id="email" autocomplete="email"
              class="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 border-none" />
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="message" class="block text-sm/6 font-semibold text-gray-900">Message</label>
          <div class="mt-2.5">
            <textarea v-model="formData.message" name="message" id="message" rows="4"
              class="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 border-none" 
              placeholder="Tell us more about your business and your use case"
              />
          </div>
        </div>
        <!-- <SwitchGroup as="div" class="flex gap-x-4 sm:col-span-2">
            <div class="flex h-6 items-center">
              <Switch v-model="agreed" :class="[agreed ? 'bg-indigo-600' : 'bg-gray-200', 'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                <span class="sr-only">Agree to policies</span>
                <span aria-hidden="true" :class="[agreed ? 'translate-x-3.5' : 'translate-x-0', 'size-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out']" />
              </Switch>
            </div>
            <SwitchLabel class="text-sm/6 text-gray-600">
              By selecting this, you agree to our
              {{ ' ' }}
              <a href="#" class="font-semibold text-indigo-600">privacy&nbsp;policy</a>.
            </SwitchLabel>
          </SwitchGroup> -->
      </div>

      <div class="mt-10 flex justify-center ">
        <Button text="Request a demo" type="submit" @click="submitForm" />
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import ButtonInverted from '../buttons/ButtonInverted.vue';
import Button from '../buttons/Button.vue';
import { projectFunctions } from "@/firebase/config";
import useCollection from '@/composables/useCollection';
import { useRouter } from "vue-router";

const router = useRouter()


const sendEmail = projectFunctions.httpsCallable("sendEmail");
const { addDoc, error } = useCollection('email_subscriptions')

const agreed = ref(false)
const formData = ref({
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  message: '',
})

const submitForm = async () => {
  await addDoc({ firstName: formData.value.firstName, lastName: formData.value.lastName, company: formData.value.company, email: formData.value.email, message: formData.value.message, timestamp: new Date() })
  try {
    sendEmail({...formData.value})
    formData.value = {
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      message: '',
    }
  } catch (err) {
    console.log(err)
  }
  router.push({ name: 'Subscribed' })
}
</script>