<template>
    <div class="bg-gray-900 py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <h2 class="col-span-2 text-pretty text-4xl font-semibold tracking-tight text-gray-100 sm:text-5xl">Built for Trust, Secured for You.</h2>
          <dl class="col-span-3 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
            <div v-for="feature in features" :key="feature.name">
              <div class=" p-6 rounded-xl h-full">
                  <dt class="text-base/7 font-semibold text-gray-200">
                    <div class="mb-6 w-10 h-10 flex items-center justify-center rounded-lg bg-indigo-600">
                      <component :is="feature.icon" class=" w-6 h-6 text-white" aria-hidden="true" />
                    </div>
                    {{ feature.name }}
                  </dt>
                  <dd class="mt-4 text-base/7 text-gray-400">{{ feature.description }}</dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { CloudIcon, LockClosedIcon, ServerStackIcon, ShieldCheckIcon, DocumentCheckIcon } from '@heroicons/vue/20/solid';
  
  const features = [
    {
      name: 'Robust Data Security',
      description:
        'Fine-grained access rules ensure restricted and secure data access. Data is stored with industry encryption standards (AES-256 in transit and at rest).',
      icon: LockClosedIcon,
    },
    {
      name: 'Reliable Infrastructure​',
      description:
        'Deployment clusters distributed across multiple regions for fault tolerance and compliance with regional data regulations. Seamless scalability to handle increasing loads without compromising performance or security.​.',
      icon: ServerStackIcon,
    },
    {
      name: 'End-to-End Network Security​',
      description:
        'VPN-secured services protect against unauthorized access and ensure private communication channels.​.',
      icon: ShieldCheckIcon,
    },
    {
      name: 'Adherence to Best Practices​',
      description:
        'Adherence to global security standards ensures data integrity and trustworthiness.',
      icon: DocumentCheckIcon,
    },
  
  ]
  </script>