<template>
    <div class="bg-gray-900">

        <div class="relative isolate  text-white pb-48 py-32">

            <div class=" mx-auto max-w-7xl px-6 ">
                <!-- Title -->
                <h2
                    class="text-left texmax-w-2xl text-4xl font-semibold tracking-tight text-gray-100 sm:text-5xl pb-24">
                    A solution for everyone</h2>
                <div class=" grid gap-x-16 gap-y-16 lg:grid-cols-2 ">
                    <!-- Cards Wrapper -->
                    <CustomerCard v-for="item in contents" :key="item.title" :content="item" />
                    <!-- Cards Wrapper -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import CustomerCard from "@/components/cards/CustomerCard.vue";

const contents = [
    {
        title: "For Angel Investors",
        description: "Elevate your sourcing and due diligence process: discover promising startups, assess potential risks, and monitor your portfolio’s progress with ease. Our platform empowers angel investors to enhance their expertise, collaborate with fellow investors, and maintain control over their investments, ensuring that both early-stage ventures and long-term goals are well-supported for sustained success.",
        image: "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=4740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        title: "For VC/PE Investors",
        description: "Maximize your investments and impact: source high-potential deals, streamline due diligence, monitor portfolio performance, and collaborate seamlessly across teams. Leverage our platform to enhance decision-making with AI-powered insights, develop scalable strategies, and maintain an edge in a competitive market, all while staying mobile-first to support the dynamic needs of VC funds.",
        image: "https://images.unsplash.com/photo-1570126618953-d437176e8c79?q=80&w=2878&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }]

</script>

<style scoped></style>