<template>
  <div class="bg-white">
    <LandingPageHeader @clicked-on-demo-button="scrollToContactSalesSection"
      @clickedOnProductButton="scrollToProductSection" @clickedOnCustomersButton="scrollToCustomersSection"
      @clickedOnFeaturesButton="scrollToFeaturesSection" @clickedOnSecurityButton="scrollToSecuritySection" />
    <div class="relative isolate pt-4">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true">
        <div
          class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
      <!-- Bakcgorund quadrants -->

      <svg
        class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true">
        <defs>
          <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-1"
            patternUnits="userSpaceOnUse">
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
          <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
            stroke-width="0" />
        </svg>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
      </svg>




      <!-- Content Start-->
      <div id="product-mobile" class="block sm:hidden">
        <BentoGridMobile/>
      </div>

      <!-- Bento Grid Begin-->
      <div id="product" class="hidden sm:block">
        <BentoGrid />
      </div>
      <!-- Bento Grid End-->

      <!-- Call to demo section -->
      <div class="">
        <CallToDemo @clickedOnDemoButtonInCallToDemo="scrollToContactSalesSection" />
      </div>
      <!-- Call to demo section end-->

      <!-- Customers Banner Begin-->
      <!-- Customers Banner End-->
      <div class="my-32"></div>


      <!--- Solutin Targets Start-->
      <div id="customers" class="scroll-section">
        <SolutionTargets />
      </div>
      <!--- Solutin Targets End-->

      <div class="my-32"></div>


      <!-- Features Navbar Start -->
      <div id="features" class="scroll-section">
        <nav class="flex justify-between items-center mx-auto max-w-2xl  lg:max-w-7xl px-6 md:px-0 "
          aria-label="Global">
          <h2 class=" max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">All your needs
            <br />in one platform.
          </h2>
          <div class=" ">
            <!-- <div class="grid grid-cols-1 sm:hidden ">
              <select aria-label="Select a tab" @change="handleChange($event)"
                class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600">

                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
              </select>

            </div> -->
            <div class="block">
              <nav class="flex flex-col space-y-1 sm:flex-row space-x-1 border-2 border-gray rounded-xl bg-white p-2 " aria-label="Tabs">
                <a v-for="tab in tabs" :key="tab.name" class="cursor-pointer rounded-md px-3 py-2 text-sm font-medium'"
                  :class="[
                    tab.current ? tab.text_color : 'text-gray-600 hover:text-gray-700',
                    tab.current ? tab.bg_color : 'hover:bg-gray-100',
                  ]" :aria-current="tab.current ? 'page' : undefined" @click="setActiveTab(tab.name)">{{ tab.name
                  }}</a>
              </nav>
            </div>
          </div>
        </nav>
        <!-- Features Navbar End -->
        <!-- Features Card Begin-->
        <div v-show="openFeatureTabName === productFeature1.title">
          <FeatureCardLeft :content=productFeature1 />
        </div>
        <div v-show="openFeatureTabName === productFeature2.title">
          <FeatureCardLeft :content=productFeature2 />
        </div>
        <div v-show="openFeatureTabName === productFeature3.title">
          <FeatureCardLeft :content=productFeature3 />
        </div>
        <div v-show="openFeatureTabName === productFeature4.title">
          <FeatureCardLeft :content=productFeature4 />
        </div>
        <div v-show="openFeatureTabName === productFeature5.title">
          <FeatureCardLeft :content=productFeature5 />
        </div>
      </div>
      <!-- Features Card End-->

      <div class="border-t border-gray-300  mx-auto my-32"></div>


      <!-- Technology Section Begin-->
      <div id="security" class="scroll-section">
        <Technology />
      </div>
      <!-- Technology Section End-->

      <div class="border-t border-gray-300  mx-auto mt-32"></div>

      <!-- Contact Sales-->
      <div id="contact-sales">
        <ContactSales />
      </div>
      <!-- Contact Sales End-->




      <div class="py-12 sm:py-12">


        <!-- Footer section -->

        <!-- Footer -->
        <footer aria-labelledby="footer-heading" class="relative text-gray-900">
          <h2 id="footer-heading" class="sr-only">Footer</h2>
          <div class="mx-auto max-w-7xl px-6  pt-16 lg:px-8">
            <div class="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
              <div class="flex space-x-6 md:order-2">
                <!-- <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
            </a> -->
              </div>
              <p class="mt-8 text-xs leading-5 text-gray-900 md:order-1 md:mt-0">&copy; 2025 cortAIx, Inc. All rights
                reserved.</p>
            </div>
          </div>
        </footer>
        <!-- Footer end -->

        <GDPRBanner />


      </div>

    </div>
  </div>
</template>

<script setup>
import CallToDemo from '@/components/landing-page/CallToDemo.vue';
import LandingPageHeader from '@/components/landing-page/LandingPageHeader.vue'
import useCollection from '@/composables/useCollection';
const { addDoc, error } = useCollection('email_subscriptions')
import { useRouter } from "vue-router";
import BentoGrid from '@/components/landing-page/BentoGrid.vue';
import SolutionTargets from '@/components/landing-page/SolutionTargets.vue';
import FeatureCardLeft from '@/components/landing-page/features/FeatureCardLeft.vue';
import { ref } from 'vue'
import Technology from '@/components/landing-page/Technology.vue';
import ContactSales from '@/components/landing-page/ContactSales.vue';
import BentoGridMobile from '@/components/landing-page/BentoGridMobile.vue';
import {
  ArrowPathIcon,
  FingerPrintIcon,
  LockClosedIcon,
  MagnifyingGlassCircleIcon,
  DocumentChartBarIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckIcon, CloudArrowUpIcon, ServerIcon, ChevronDownIcon,
  MapIcon,ArrowTrendingUpIcon,PaperAirplaneIcon,
  ChatBubbleBottomCenterTextIcon,DocumentMagnifyingGlassIcon,ServerStackIcon,MagnifyingGlassIcon,ShareIcon,UserGroupIcon,
  AcademicCapIcon,ChartPieIcon,UsersIcon,LinkIcon,BellAlertIcon

} from '@heroicons/vue/20/solid'

import GDPRBanner from '@/components/landing-page/GDPRBanner.vue';

import { projectFunctions } from "@/firebase/config";
const sendEmail = projectFunctions.httpsCallable("sendEmail");
const email = ref('')
const router = useRouter()
const currentFeataurePic = ref("https://tailwindui.com/img/component-images/project-app-screenshot.png")
function changeCurrent(feature) {

  currentFeataurePic.value = feature.pic
  featuresWithPics.forEach(item => {
    item.current = false
  })
  feature.current = true
}

const scrollToContactSalesSection = () => {
  document.getElementById('contact-sales').scrollIntoView({ behavior: 'smooth' })
}
const scrollToFeaturesSection = () => {
  document.getElementById('features').scrollIntoView({ behavior: 'smooth' })
}
const scrollToCustomersSection = () => {
  document.getElementById('customers').scrollIntoView({ behavior: 'smooth' })
}
const scrollToSecuritySection = () => {
  document.getElementById('security').scrollIntoView({ behavior: 'smooth' })

}
const scrollToProductSection = () => {
  document.getElementById('product').scrollIntoView({ behavior: 'smooth' })
}

const handleSubmit = async () => {
  if (email.value) {
    await addDoc({ email: email.value, timestamp: new Date() })
    try {
      sendEmail({ email: email.value })
      email.value = ''
    } catch (err) {
      console.log(err)
    }
    router.push({ name: 'Subscribed' })
  }
}

const tabs = ref([
  { name: 'Identify new deals', href: '#', current: true, text_color: "text-indigo-500", bg_color: "bg-indigo-100" },
  { name: 'Carry out the due diligence', href: '#', current: false, text_color: "text-red-500", bg_color: "bg-red-100" },
  { name: 'Monitor your porfolio', href: '#', current: false, text_color: "text-green-500", bg_color: "bg-green-100" },
  { name: 'Enhance your market expertise', href: '#', current: false, text_color: "text-orange-500", bg_color: "bg-orange-100" },
  { name: 'Stay informed', href: '#', current: false, text_color: "text-purple-500", bg_color: "bg-purple-100" },
])

const openFeatureTabName = ref(tabs.value[0].name)

function setActiveTab(tabName) {
  tabs.value.forEach((tab) => {
    tab.current = tab.name === tabName
  })
  openFeatureTabName.value = tabName
}

const handleChange = (event) => {
  const selectedTabName = event.target.value;
  tabs.value.forEach((tab) => {
    tab.current = tab.name === selectedTabName;
  });
  openFeatureTabName.value = selectedTabName;
  console.log(event.target.value);

};


const productFeature1 = {
  title: tabs.value[0].name,
  description: '',
  image: "feature11.png",
  color_main: "bg-indigo-500",
  color_second: "bg-indigo-100",
  text_color: "text-indigo-500",
  border_color: "border-indigo-500",
  features: [
  {
      name: 'Map based discovery.​',
      description:
        'Discover new investment opportunities near your areas of influence.​',
      icon: MapIcon,
    },
    {
      name: 'Tailor made recommendation system​.',
      description: 'Get company suggestions that match your investment criteria (company size, funding, location, etc.).',
      icon: ArrowTrendingUpIcon,
    },
    {
      name: 'Contact directly the founders​.',
      description: 'Access company executives contacts through Cortaix and schedule an initial meeting​.',
      icon: PaperAirplaneIcon,
    },
  ]
}

const productFeature2 = {
  title: tabs.value[1].name,
  description: '',
  image: "feature2.png",
  color_main: "bg-red-500",
  color_second: "bg-red-100",
  text_color: "text-red-500",
  border_color: "border-red-500",
  features: [
  {
      name: 'Chat with companies and generate one-pagers.',
      description:
        'Leverage our expert AI agents to access in-depth company insights and receive a concise, professionally crafted one-pager on the companies that interest you.',
      icon: ChatBubbleBottomCenterTextIcon,
    },
    {
      name: 'Due diligence and Memo',
      description: 'Carry out a complete due diligence of your investment target (competition analysis, market analysis, KPIs, investment recommendations, etc.) and receive a memo to share with your colleagues.',
      icon: DocumentMagnifyingGlassIcon,
    },
    {
      name: 'Dataroom.',
      description: 'Import the data room, identify key data and perform analyses using our agents.',
      icon: ServerStackIcon,
    },
  ]
}

const productFeature3 = {
  title: tabs.value[2].name,
  description: '',
  image: "feature32.png",
  color_main: "bg-green-500",
  color_second: "bg-green-100",
  text_color: "text-green-500",
  border_color: "border-green-500",
  features: [
    {
      name: 'Track your portfolio companies.',
      description:
        'Be notified of new information on your portfolio companies (recruitment, monitoring of competitors, the market, regulations, etc.).',
      icon: MagnifyingGlassIcon,
    },
    {
      name: 'Share key information.',
      description: 'Share key information with founders and conduct in-depth research with the help of our expert agents.',
      icon: ShareIcon,
    },
    {
      name: 'Better collaboration on your todos.',
      description: 'Collaborate internally on your todos to help business leaders as much as possible.',
      icon: UserGroupIcon,
    },
  ]
}

const productFeature4 = {
  title: tabs.value[3].name,
  description: '',
  image: "feature4.png",
  color_main: "bg-orange-500",
  color_second: "bg-orange-100",
  text_color: "text-orange-500",
  border_color: "border-orange-500",
  features: [
    {
      name: 'Custom resource library​.',
      description:
        'Receive information on the markets that interest you (articles, blogs, podcasts, videos, events, etc.)​.',
      icon: AcademicCapIcon,
    },
    {
      name: 'In-depth analyses​.',
      description: 'Carry out in-depth market analyses thanks to our expert market agents.',
      icon: ChartPieIcon,
    },
    {
      name: 'Consolidation of team expertise​.',
      description: 'Share your market analyses with your team and collaborate on your todos.',
      icon: UsersIcon,
    },
  ]
}

const productFeature5 = {
  title: tabs.value[4].name,
  description: '',
  image: "feature5.png",
  color_main: "bg-purple-500",
  color_second: "bg-purple-100",
  text_color: "text-purple-500",
  border_color: "border-purple-500",
  features: [
    {
      name: 'Connecting to your tools​.',
      description:
        'Integrate your CRM, emails and tools with cortaix.​',
      icon: LinkIcon,
    },
    {
      name: 'Activity feed.',
      description: 'Receive information related to your interests, your activity and that of your team.',
      icon: BellAlertIcon,
    }
  ]
}



const features = [
  {
    name: 'Comprehensive Company Research.',
    description: 'Access detailed company information, including social links and AI-driven investment analysis.',
    icon: MagnifyingGlassCircleIcon,
  },
  {
    name: 'Document-driven Analysis.',
    description: 'Upload and analyze pitch decks or quarterly reports for informed investment decisions with AI insights.',
    icon: DocumentChartBarIcon,
  },
  {
    name: 'Private AI Analysis',
    description: 'Ensure confidentiality with a secure and private company hosted AI solutions.',
    icon: LockClosedIcon,
  },
  {
    name: 'Secure Data Storage.',
    description: 'Safeguard uploaded documents and customer data with robust security measures.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Interactive Virtual Agent. ',
    description: 'Engage in real-time Q&A sessions with a virtual agent for immediate insights via a user-friendly chat interface.',
    icon: ChatBubbleLeftEllipsisIcon,
  },
  {
    name: 'AI Capability Enhancement.',
    description: 'We leverage user data to continuously enhance and improve the AI capabilities, ensuring personalized and evolving analysis for users.',
    icon: ArrowPathIcon,
  },
]

const featuresWithPics = [
  {
    current: true,
    name: 'Efficient Company Discovery:',
    description: 'Explore a comprehensive company index to swiftly locate entities of interest. Discover company names and descriptions, streamlining your initial research process.',
    icon: MagnifyingGlassCircleIcon,
    pic: 'https://tailwindui.com/img/component-images/project-app-screenshot.png'
  },
  {
    current: false,
    name: 'Tailored Inquiry System:',
    description: 'Engage our intelligent query system to seek precise information. Simply provide your own documents such as pitch decks and quarterly reports. Our platform then utilizes Retrieval Augmented Generation (RAG) technology to analyze websites and social links, providing tailored responses to your questions.',
    icon: DocumentChartBarIcon,
    pic: 'https://tailwindui.com/img/component-images/dark-project-app-screenshot.png'
  },
  {
    current: false,
    name: 'Verified Sources, Verified Answers:',
    description: 'Trust in the authenticity of our responses, backed by meticulous source verification. Each answer is accompanied by direct links to credible documents and web pages, empowering you with reliable information for informed decision-making.',
    icon: ArrowPathIcon,
    pic: 'https://tailwindui.com/img/component-images/dark-project-app-screenshot.png'
  },
  {
    current: false,
    name: 'Interactive Chatbot Assistance:',
    description: 'Enhance your exploration with our intuitive chatbot feature. Seamlessly pose follow-up questions or seek further clarification, gaining real-time insights tailored to your investment needs as a VC investor.',
    icon: ChatBubbleLeftEllipsisIcon,
    pic: 'https://tailwindui.com/img/component-images/dark-project-app-screenshot.png'
  },
]

// Footer
import { defineComponent, h } from 'vue'

const footerNavigation = {
  solutions: [
    { name: 'Hosting', href: '#' },
    { name: 'Data Services', href: '#' },
    { name: 'Uptime Monitoring', href: '#' },
    { name: 'Enterprise Services', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Reference', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
            }),
          ]),
      }),
    },
    {
      name: 'GitHub',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
  ],
}

// Pricing
const includedFeatures = [
  '20 reports',
  'Unlimited virtual assistant support',
  'Advanced Anayltics',
  '24-hour support response time',
]
</script>
<style scoped>
.scroll-section {
  scroll-margin-top: 100px;
  /* Adjust this value to match your navbar height */
}
</style>