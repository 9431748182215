<template>
    <div class="bg-inherit py-4 sm:py-4 ">
        <div class="mx-auto max-w-7xl px-6  lg:flex lg:items-center  lg:px-8 justify-between">
            <h2 class="basis-2/3 max-w-3xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">The AI Investment Companion:
                <br class="mt-2"/>close the right deals, faster.</h2>
                
            <div class="flex flex-col  gap-y-6 lg:mt-0  w-96">
                <p class=" text-gray-600 pt-6">The intelligent solution that addresses all your investment challenges: collect, analyze, and act.</p>
                <div class="" @click="sendButtonClickedEvent">
                    <ButtonInverted text="Request a demo" :disabled="false"  />
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import ButtonInverted from "@/components/buttons/ButtonInverted.vue";
import { defineEmits } from 'vue';
const emit = defineEmits(['clickedOnDemoButtonInCallToDemo']);

const sendButtonClickedEvent = () => {
  emit('clickedOnDemoButtonInCallToDemo');
};
</script>