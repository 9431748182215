<template>
    <div class="bg-inherit py-6 sm:py-6">
      <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <!-- <h2 class="text-center text-base/7 font-semibold text-indigo-600">Deploy faster</h2> -->
        <!-- <p class="mx-auto mt-2 max-w-lg text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl">Everything you need to deploy your app</p> -->
        <div class="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
          <div class="relative lg:row-span-2">
            <div class="absolute inset-px rounded-lg bg-white lg:rounded-l-[2rem]" />
            <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
              <div class="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p class="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">Mobile friendly</p>
                <p class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">Designed for <span class="custom-text">mobility</span>, perfect for VC investors on the move with a mobile-first approach for seamless access anywhere.</p>
              </div>
              <div class="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                <div class="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] bg-inherit ">
                  <img class="size-full object-cover object-top" src="@/assets/cortaix-mobile.png" alt="" />
                </div>
              </div>
            </div>
            <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]" />
          </div>
          <div class="relative max-lg:row-start-1">
            <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem]" />
            <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
              <div class="px-8 pt-8 sm:px-10 sm:pt-10">
                <p class="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">AI powered Quality</p>
                <p class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">Leverage AI with <span class="custom-text">agentic reasoning</span> to deliver tailored insights documents for investment decision-making.</p>
              </div>
              <div class="mx-auto px-8  pt-2 max-lg:pt-10 sm:px-10 lg:pb-2 w-96 h-32 overflow-hidden ">
                <img class="w-full h-full object-cover object-top" src="@/assets/screen.png" alt="" />
              </div>
            </div>
            <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]" />
          </div>
          <div class="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
            <div class="absolute inset-px rounded-lg bg-white" />
            <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
              <div class="px-8 pt-8 sm:px-10 sm:pt-10">
                <p class="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">Security</p>
                <p class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">Flexible and adaptive, ensuring robust <span class="custom-text">protection</span> and <span class="custom-text">compliance</span> tailored to the industry’s unique <span class="custom-text">regulatory needs</span>.</p>
              </div>
              <div class="flex flex-1 items-center [container-type:inline-size] max-lg:py-6 lg:pb-2 ">
                <img class="h-[min(152px,40cqw)] object-cover" src="https://tailwindui.com/plus/img/component-images/bento-03-security.png" alt="" />
              </div>
            </div>
            <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
          </div>
          <div class="relative lg:row-span-2">
            <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]" />
            <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
              <div class="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p class="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">Powerful Integrations</p>
                <p class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">Effortlessly connect with your favorite tools for a streamlined workflow.</p>
              </div>
              <div class="relative min-h-[30rem] w-full grow">
                <div class="absolute bottom-0  right-0 top-10 overflow-hidden rounded-tl-xl bg-inherit shadow-2xl">
                    <img class="h-full object-cover" src="@/assets/cortaix-integrations.png" alt="" />
                </div>
              </div>
            </div>
            <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]" />
          </div>
        </div>
      </div>
    </div>
  </template>
  <style scoped>
  .custom-text {
  font-weight: 600;
  color: #333333;
}
</style>
  